import React from 'react';
import './PriceListing.css';
import './Cards.css';

function PriceListing() {
    return (
        <div className='pricingContainer'>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <li className='cards__item'>
                            <div className='cards__item__info'>
                                <h3 className='cards__item__prompt'>Fyrsti hálftíminn</h3>
                                <h5 className='cards__item__price'>8.000kr</h5>
                            </div>
                        </li>
                        <li className='cards__item'>
                            <div className='cards__item__info'>
                                <h3 className='cards__item__prompt'>Byrjaður hálftími eftir sá fyrsta</h3>
                                <h5 className='cards__item__price'>5.000kr</h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='averageTime'>
                <p className='averageHeader'>Tíma viðmið:</p>
                <ul className='averageList'>
                    <li className='listElement'>
                        <p className='listText'>Router uppsetning: </p>
                        <p className='listTime'>30min - 1klst</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Prentara uppsetning: </p>
                        <p className='listTime'>30min - 1klst</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Prentara stífla: </p>
                        <p className='listTime'>1klst</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Tölvu uppsetning: </p>
                        <p className='listTime'>30min - 1klst</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Síma uppsetning: </p>
                        <p className='listTime'>30min</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Lítil uppfærsla á vélbúnað tölvu: </p>
                        <p className='listTime'>30min</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Stór uppfærsla á vélbúnað tölvu: </p>
                        <p className='listTime'>1klst - 1,5klst</p>
                    </li>
                    <li className='listElement'>
                        <p className='listText'>Hugbúnaðar uppsetning: </p>
                        <p className='listTime'>30min - 1,5klst</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PriceListing
