import React from 'react';
import ContactInfo from '../ContactInfo.js';

function Contact() {
  return (
    <div>
        <ContactInfo/>
    </div>
  )
}

export default Contact
