import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    //const [button, setButton] = useState(true);
    //const [lang, setLang] = useState(false);

    const handleClick = () => setClick(!click);
    //const handleLang = () => setLang(!lang);
    const closeMobileMenu = () => setClick(false);

    /*const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }else{
            setButton(true);
        }
    };
    
    useEffect(() => {
        showButton();
    }, []);
    
    window.addEventListener('resize', showButton);
    */

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        Tæknimenn
                    </Link>
                    <div className='item-container'>
                        <div className='menu-icon' onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            {/*
                            <li className='nav-item'>
                                <Link to='/order' className='nav-links' onClick={closeMobileMenu}>
                                    Panta tíma
                                </Link>
                            </li>
                            */}
                            <li className='nav-item'>
                                <Link to='/pricing' className='nav-links' onClick={closeMobileMenu}>
                                    Verðskrá
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                    Hafa samband
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
