import React from 'react';
import './Cards.css';
import { Button } from './Button';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <li className='cards__item'>
              <div className='cards__item__info'>
                  <h3 className='cards__item__prompt'>Ekki viss hvort við getum hjálpað þér?</h3>
                  <h5 className='cards__item__text'>Heyrðu í okkur og finnum út úr því</h5>
                  <Button
                      className='btns'
                      buttonStyle='btn--primary'
                      buttonSize='btn--large'
                      linkTo='/contact'
                  >
                      Hafa samband
                  </Button>
              </div>
            </li>
            <li className='cards__item'>
              <div className='cards__item__info'>
                  <h3 className='cards__item__prompt'>Verðskrá</h3>
                  <h5 className='cards__item__price'>
                    Fyrsti hálftíminn kostar 8.000kr<br/>
                    Eftir það er hver byrjaður hálftími 5.000kr
                  </h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;