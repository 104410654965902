import React from 'react';
import '../../App.css';
import DateTime from '../DateTime';

function Home () {
    return (
        <>
            <DateTime/>
        </>
    )
}

export default Home;