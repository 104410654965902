import React from 'react';
import { Button } from './Button';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <div className='hero-text-container'>
                <h1>Vantar þig aðstoð við tæknimál?</h1>
                <p>
                    <i class="fa-solid fa-circle fa-2xs"/> Uppsetningar á tölvum, netgræjum, prenturum, og fleira<br/>
                    <i class="fa-solid fa-circle fa-2xs"/> Uppfærslur á borð- og fartölvum<br/>
                    <i class="fa-solid fa-circle fa-2xs"/> Almenn tækniaðstoð
                </p>
                <div className='hero-btns'>
                    <Button 
                        className='btns'
                        buttonStyle='btn--outline'
                        buttonSize='btn--large'
                        linkTo='/contact'
                    >
                        Panta tíma
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
