import React from 'react';
import PriceListing from '../PriceListing';

function Prices() {
  return (
    <div>
      <PriceListing/>
    </div>
  )
}

export default Prices
