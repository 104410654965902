import { React, useState, useEffect } from 'react';
import data from '../data/availTime.json';
import './DateTime.css';


function validDate(date){
    let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[-](0?[1-9]|1[0-2])/;

    if (date.match(dateformat)) {
        let operator = date.split('-');

        let datepart = [];
        if (operator.length > 1) {
            datepart = date.split('-');
        }
        let day = parseInt(datepart[0]);
        let month = parseInt(datepart[1]);
        let year = parseInt(datepart[2]);

        let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (month == 1 || month > 2) {
            if (day > ListofDays[month - 1]) {
                return false;
            }
        } else if (month == 2) {
            let leapYear = false;
            if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
            if ((leapYear == false) && (day >= 29)) return false;
            else
                if ((leapYear == true) && (day > 29)) {
                    return false;
                }
        }
    } else {
        return false;
    }
    return true;
}


function DateTime() {
    let date = new Date();
    //let dateFormatted = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth()+1).padStart(2,"0")}-${date.getFullYear()}`
    
    const availTimes = data;
    const workinTimes = ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];
    var dateElement = [];
    var timeElement = [];
    var currentWeek = [];
    var currentDay = String(date.getDate()+1).padStart(2, '0');
    var currentMonth = String(date.getMonth()+1).padStart(2,"0");
    var currentYear = date.getFullYear();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [unavailText, setUnavailText] = useState(false);
    const [availSelected, setAvailSelected] = useState(false);
    const [selectedService, setSelectedService] = useState(null);


    function SelectDate(selected){
        setSelectedTime(null);
        if(selected===selectedDate){
            setSelectedDate(null);
        }else{
            setSelectedDate(selected);
        }
    }

    function SelectTime(selected){
        if(selected===selectedTime){
            setSelectedTime(null);
        }else{
            setSelectedTime(selected);
        }
    }

    useEffect(() => {
        if(selectedTime===null){
            setUnavailText(false);
            setAvailSelected(false);
        }else{
            if(availTimes.hasOwnProperty(currentWeek[selectedDate]) && availTimes[currentWeek[selectedDate]].hasOwnProperty(selectedTime)){
                setUnavailText(false);
                setAvailSelected(true);
            }else{
                setUnavailText(true);
                setAvailSelected(false);
            }
        }
    }, [selectedTime])

    for(let i = 0; i < 7; i++){
        if(!validDate(`${currentDay}-${currentMonth}-${currentYear}`)){
            currentDay = '01';
            if(currentMonth==='12'){
                currentMonth = '01';
                currentYear++;
            }else{
                currentMonth++;
                if(currentMonth<10){
                    currentMonth = `0${currentMonth}`;
                }
            }
        }
        currentWeek.push(`${currentDay}-${currentMonth}-${currentYear}`);
        currentDay++;
        if(currentDay<10){
            currentDay = `0${currentDay}`;
        }
    }

    for(let i = 0; i < currentWeek.length; i++){
        if(availTimes.hasOwnProperty(currentWeek[i])){
            dateElement.push(<button key={i} onClick={() => SelectDate(i)} className={i===selectedDate ? 'availDay active' : 'availDay'} >{currentWeek[i]}</button>)
        }else{
            dateElement.push(<button key={i} onClick={() => SelectDate(i)} className={i===selectedDate ? 'unavailDay active' : 'unavailDay'} >{currentWeek[i]}</button>)
        }
    }

    function WeekMap(week){
        if(availTimes.hasOwnProperty(week)){
            return workinTimes.map((t) => {
                if(availTimes[week].hasOwnProperty(t)){
                    return (<button key={t} onClick={() => SelectTime(t)} className={t===selectedTime ? 'availTime active' : 'availTime'} >{t}</button>)
                }else{
                    return (<button key={t} onClick={() => SelectTime(t)} className={t===selectedTime ? 'unavailTime active' : 'unavailTime'} >{t}</button>)
                }
            })
        }else{
            return workinTimes.map(t => <button key={t} onClick={() => SelectTime(t)} className={t===selectedTime ? 'unavailTime active' : 'unavailTime'} >{t}</button>);
        }
    }

    function SelectService(event){
        setSelectedService(event.target.value);

    }

    timeElement = currentWeek.map(WeekMap);

    return (
        <div className='datePicker'>
            <h1 className='dateTime_prompt'>Veldu dagsetningu og tíma</h1>
            <ul className='available_dates'>
                {dateElement}
            </ul>
            {selectedDate!=null &&
                <ul className='timeSelect'>
                    {timeElement[selectedDate]}
                </ul>
            }
            {unavailText &&
                <p className='unavailText'>Þessi tími er ekki laus, veldu lausann tíma til að panta</p>
            }
            {availSelected &&
                <form className='inputForm'>
                    <select id="inputService" onChange={SelectService} className='inputSelect'>
                        <option disabled selected value>Veldu þjónustu</option>
                        <option value="router">Router uppsetning/stilling</option>
                        <option value="computerUpgrade">Tölvu uppfærsla</option>
                        <option value="printer">Prentara uppsetning/stilling</option>
                        <option value="other">Annað</option>
                    </select>

                    {selectedService==='other' &&
                        <textarea id="inputMessage" rows="3" required placeholder="Þjónusta" className='inputService'></textarea>
                    }
                    {selectedService!='other' &&
                        <p className='selectExtra'>Áætlaður tími</p>
                    }

                    <input type="text" id="inputName" placeholder="Nafn" required className='inputName'></input>

                    <input type="tel" id="inputPhoneNumber" placeholder="Símanúmer" required className='inputPhoneNumber'></input>

                    <input type="email" id="inputEmail" placeholder="Netfang" required className='inputEmail'></input>
                    
                    <textarea id="inputMessage" rows="3" placeholder="Annað sem þú vilt koma fram" className='inputMessage'></textarea>

                    <button id="sendMessage" className='inputButton'>Senda fyrirspurn</button>
                </form>
            }

        </div>
    )
}

export default DateTime
